/**
 * the minimum amount of characters used for password
 */
export const PASSWORD_MIN_CHARACTER_LENGTH = 6;

/**
 * the minimum requirements for the password,
 * currently is needs to contain at least
 * 6 characters,
 * 1 figure
 * 1 special character
 */
export const PASSWORD_MIN_REQUIREMENTS_REGEX = /(?=.*?\d)(?=.*?(\W|_))/;

/**
 * checks if password matches the min requirements
 * @param password
 */
export const validatePasswordContent = (password: string): boolean => {
    return PASSWORD_MIN_REQUIREMENTS_REGEX.test(password);
};

/**
 * checks if password has at least six characters
 * @param password
 */
export const validateMinCharacter = (password: string): boolean => {
    return password.length >= PASSWORD_MIN_CHARACTER_LENGTH;
};
