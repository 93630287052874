
import {namespace} from 'vuex-class';
import {Component} from 'vue-property-decorator';
import {AUTH_STORE_NAME, AuthStoreActions} from '@/store/auth.store';
import {ROUTE_HOME, ROUTE_LOGIN} from '@/router/routes';
import {email, required} from 'vuelidate/lib/validators';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {mixins} from 'vue-class-component';
import Restauranteur from '@/models/Restaurateur';
import {VALIDATION_STORE_NAME, ValidationStoreGetters} from "@/store/validation.store";
import Validations from "@/models/Validations.model";
import {maxLength} from "@/misc/CustomValidators";
import {MaxLengthValidation} from "@/enum/MaxLengthValidation.enum";
import {validateMinCharacter, validatePasswordContent} from "@/misc/ValidatePassword";

const AuthStore = namespace(AUTH_STORE_NAME);
const ValidationStore = namespace(VALIDATION_STORE_NAME);

@Component({
  components: {
    LogoComponent: () => import(
        /* webpackChunkName: "LogoComponent" */
        '@/components/Logo.component.vue'
        ),
  },
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(MaxLengthValidation.EMAIL)
    },
    password: {
      required,
      maxLength: maxLength(MaxLengthValidation.PASSWORD)
    },
    company: {
      required,
      maxLength: maxLength(MaxLengthValidation.COMPANY_NAME)
    }
  }
})
export default class RegisterView extends mixins(ErrorMessageHandlerMixin) {
  @AuthStore.Action(AuthStoreActions.REGISTER_RESTAURATEUR)
  private registerAction!: (payload: { email: string, password: string, companyName: string }) => Promise<Restauranteur | null>;

  /**
   * Credentials
   * @private
   */
  private email: string = '';
  private password: string = '';
  private company: string = '';

  private hasMinimumCharacters: boolean = false;
  private hasMinimumRequirements: boolean = false;
  private showSuggestions: boolean = false;

  /**
   * Loading State bool
   * @private
   */
  private isLoading: boolean = false;

  /**
   * Is Mobile Bool to determine if the screen is small enough to be a mobile device
   */
  public get isMobile() {
    return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
  }

  /**
   * Hide Password State bool to either show and Hide the Password inside the TextField
   * @private
   */
  private hidePasswords: boolean = true;

  /**
   * redirects the user to the home view if he/she is logged in
   * @private
   */
  private created() {
    // Redirect to home if already logged in:
    if (this.$isLoggedIn()) {
      this.$router.push({name: ROUTE_HOME});
    }
  }

  /**
   * validates if the password has correct requirements, checks
   * if the password has minimum length of six and if the there is at least one figure and special character
   * @param value
   * @private
   */
  private onValidatePassword(value: string) {
    this.hasMinimumCharacters = validateMinCharacter(value);
    this.hasMinimumRequirements = validatePasswordContent(value);
    this.triggerValidation('password');
  }

  /**
   * Check if Form is Valid
   * if valid try to Register a new Account and listen for API Responses
   * If Invalid return so Vuelidate handles Errors first
   * @private
   */
  private async doRegistration() {
    // checks if the minimum password requirements are met
    if(!this.hasMinimumCharacters || !this.hasMinimumRequirements) {
      return;
    }

    // Trigger validation
    this.$v.$touch();

    // Form is Invalid return here
    if (this.$v.$invalid) {
      return;
    }

    // Form is Valid try to Register a new Account
    try {
      // Set Loading to true
      this.isLoading = true;

      // logs in the user, checks if the user has the correct role
      await this.registerAction({
        email: this.email, password: this.password, companyName: this.company
      });

      // Registration Successful toast
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_SUCCESSFUL');

      // pushes the application to the home view
      await this.$router.push({name: ROUTE_LOGIN});
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 409: // Email or Company already exists
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_EMAIL_OR_COMPANY_ALREADY_EXISTS');
            break;
          case 422: // Wrong Format in Credentials
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_ERROR');
        }
      });
    } finally {
      this.isLoading = false;
    }
  }
}
